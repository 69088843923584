import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import LazyScripts from 'lazy-scripts';
new LazyScripts();
function Social({ language, t }) {

    return(
        <div>
            <div id="social"></div>
       <div className="social-section">
           <h2 className="normal-headline fade" data-emergence="hidden">
               Social Media
           </h2>
        <div class="elfsight-app-1e6ff75f-a258-4ac5-abd7-3eba214b6ab1" data-lazy-script="./js/elfsight.js"></div>
        <div className="logo-section">
        <a href="https://www.instagram.com/reinhild.opera/" target="_blank" rel="noopener noreferrer">
            <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M56.0519 0H22.9483C10.2945 0 0 10.2945 0 22.9483V56.0517C0 68.7055 10.2945 79 22.9483 79H56.0517C68.7055 79 79 68.7055 79 56.0519V22.9483C79 10.2945 68.7055 0 56.0519 0ZM72.8281 56.0517C72.8281 65.3023 65.3023 72.8281 56.0519 72.8281H22.9483C13.6977 72.8281 6.17188 65.3023 6.17188 56.0519V22.9483C6.17188 13.6977 13.6977 6.17188 22.9483 6.17188H56.0517C65.3023 6.17188 72.8281 13.6977 72.8281 22.9483V56.0517Z" fill="white"/>
                <path d="M39.5 18.207C27.7589 18.207 18.207 27.7589 18.207 39.5C18.207 51.2411 27.7589 60.793 39.5 60.793C51.2411 60.793 60.793 51.2411 60.793 39.5C60.793 27.7589 51.2411 18.207 39.5 18.207ZM39.5 54.6211C31.1623 54.6211 24.3789 47.8377 24.3789 39.5C24.3789 31.1623 31.1623 24.3789 39.5 24.3789C47.8377 24.3789 54.6211 31.1623 54.6211 39.5C54.6211 47.8377 47.8377 54.6211 39.5 54.6211Z" fill="white"/>
                <path d="M61.1016 20.9844C62.8059 20.9844 64.1875 19.6028 64.1875 17.8984C64.1875 16.1941 62.8059 14.8125 61.1016 14.8125C59.3972 14.8125 58.0156 16.1941 58.0156 17.8984C58.0156 19.6028 59.3972 20.9844 61.1016 20.9844Z" fill="white"/>
            </svg>
        </a>

        <a href="https://www.facebook.com/reinhild.buchmayer" target="_blank" rel="noopener noreferrer">
            <svg width="79" height="80" viewBox="0 0 79 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5723 80H37.3398V51.5625H28.082V42.1875H37.3398V30.4687C37.3398 24.007 42.5311 18.75 48.9121 18.75H60.4844V28.125H51.2266C48.674 28.125 46.5977 30.2277 46.5977 32.8125V42.1875H60.0667L58.5237 51.5625H46.5977V80H67.4277C73.8087 80 79 74.743 79 68.2812V11.7188C79 5.25696 73.8087 0 67.4277 0H11.5723C5.19125 0 0 5.25696 0 11.7188V68.2812C0 74.743 5.19125 80 11.5723 80ZM4.62891 11.7188C4.62891 7.8418 7.74377 4.6875 11.5723 4.6875H67.4277C71.2562 4.6875 74.3711 7.8418 74.3711 11.7188V68.2812C74.3711 72.1582 71.2562 75.3125 67.4277 75.3125H51.2266V56.25H62.445L65.531 37.5H51.2266V32.8125H65.1133V14.0625H48.9121C39.9786 14.0625 32.7109 21.4221 32.7109 30.4687V37.5H23.4531V56.25H32.7109V75.3125H11.5723C7.74377 75.3125 4.62891 72.1582 4.62891 68.2812V11.7188Z" fill="white"/>
            </svg>
        </a>

        <a href="https://www.tiktok.com/@rhinedaughter?lang=en" target="_blank" rel="noopener noreferrer">
            <svg width="76" height="89" viewBox="0 0 76 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.3191 0C56.7811 12.6304 63.799 20.1605 76.0004 20.9616V35.1674C68.9294 35.8617 62.7357 33.5386 55.5317 29.1593V55.7285C55.5317 89.4806 18.9007 100.028 4.17391 75.8356C-5.28954 60.2679 0.505494 32.9511 30.863 31.8563V46.8365C28.5503 47.2103 26.0781 47.7978 23.8185 48.5722C17.0665 50.8686 13.2386 55.1677 14.3019 62.7513C16.3488 77.2775 42.8784 81.5767 40.672 53.1917V0.0267027H55.3191V0Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="76" height="89" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </a>


        </div>
       </div> 
       </div>


	);
}

export default withNamespaces()(Social);
