import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

function Media({ language, t }) {

    return(
        <div>
            <div id="media"></div>
       <div className="media-section">
           <h2 className="normal-headline fade" data-emergence="hidden">
               Media
           </h2>
           <div className="media-grid">
                <div className="item">
                    <div className="container">
                <iframe className="fade" data-emergence="hidden"
 src="https://www.youtube.com/embed/zL31lzg9RXs"
 srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}.img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/zL31lzg9RXs?autoplay=1><img class='img' src=img/iframe-1.jpg alt='Reinhild'><span><img src=img/play.svg alt='Play'></span></a>"
 frameborder="0"
 allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
 allowfullscreen
 title="Reinhild"
></iframe>
<iframe className="fade" data-emergence="hidden"
 src="https://www.youtube.com/embed/QWB_YKjuRng"
 srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}.img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/QWB_YKjuRng?autoplay=1><img class='img' src=img/iframe-2.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span><img src=img/play.svg alt='Play'></span></a>"
 frameborder="0"
 allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
 allowfullscreen
 title="Marlene Gassner Showcase"
></iframe>
</div>
                </div>
                <picture className="media-img">
                <source className="media-img" srcset="img/media.webp" type="image/webp" />
                <source className="media-img" srcset="img/media.jpg" type="image/jpeg" /> 
                <img className="media-img" src="img/media.jpg" alt="Reinhild Media Section" />
                </picture>
           </div>
       </div>
       </div>

	);
}

export default withNamespaces()(Media);
