import React from 'react';
import { withNamespaces } from 'react-i18next';

function Contact({ t, language }) {

    return(
        <div id="contact" className="contact-section">
            <div className="container-contact fade" data-emergence="hidden">
            <h2 className="normal-headline">{t('contact')}</h2>
            <div className="contact-item contact-grid">
 
 <div className="contact-item">
         <div className="hover-box">
             <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 2H33C34.925 2 36.5 3.575 36.5 5.5V26.5C36.5 28.425 34.925 30 33 30H5C3.075 30 1.5 28.425 1.5 26.5V5.5C1.5 3.575 3.075 2 5 2Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.5 5.5L19 17.75L1.5 5.5" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

 

             <div className="center-link">
                 <a href="mailto:reinhild188@gmail.com" target="_blank" rel="noopener noreferrer">
                 reinhild188@gmail.com
                 </a>
             </div>
         </div>
     </div>

     <div className="contact-item">
     
         <div className="hover-box">
             <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.9617 0H11.0384C4.9518 0 0 4.9518 0 11.0384V26.9616C0 33.0482 4.9518 38 11.0384 38H26.9616C33.0482 38 38 33.0482 38 26.9617V11.0384C38 4.9518 33.0482 0 26.9617 0ZM35.0312 26.9616C35.0312 31.4112 31.4112 35.0312 26.9617 35.0312H11.0384C6.58877 35.0312 2.96875 31.4112 2.96875 26.9617V11.0384C2.96875 6.58877 6.58877 2.96875 11.0384 2.96875H26.9616C31.4112 2.96875 35.0312 6.58877 35.0312 11.0384V26.9616Z" fill="white"/>
<path d="M19 8.75781C13.3524 8.75781 8.75781 13.3524 8.75781 19C8.75781 24.6476 13.3524 29.2422 19 29.2422C24.6476 29.2422 29.2422 24.6476 29.2422 19C29.2422 13.3524 24.6476 8.75781 19 8.75781ZM19 26.2734C14.9894 26.2734 11.7266 23.0106 11.7266 19C11.7266 14.9894 14.9894 11.7266 19 11.7266C23.0106 11.7266 26.2734 14.9894 26.2734 19C26.2734 23.0106 23.0106 26.2734 19 26.2734Z" fill="white"/>
<path d="M29.3906 10.0938C30.2104 10.0938 30.875 9.42917 30.875 8.60938C30.875 7.78958 30.2104 7.125 29.3906 7.125C28.5708 7.125 27.9062 7.78958 27.9062 8.60938C27.9062 9.42917 28.5708 10.0938 29.3906 10.0938Z" fill="white"/>
</svg>



             <div className="center-link">
                 <a href="https://instagram.com/reinhild.opera" target="_blank" rel="noopener noreferrer">
                 reinhild.opera
                 </a>
             </div>
         </div>
     </div>


         


     <div className="contact-item">
     
         <div className="hover-box">
             <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.56641 38H17.9609V24.4922H13.5078V20.0391H17.9609V14.4727C17.9609 11.4033 20.458 8.90625 23.5273 8.90625H29.0937V13.3594H24.6406C23.4128 13.3594 22.4141 14.3581 22.4141 15.5859V20.0391H28.8928L28.1506 24.4922H22.4141V38H32.4336C35.5029 38 38 35.5029 38 32.4336V5.56641C38 2.49706 35.5029 0 32.4336 0H5.56641C2.49706 0 0 2.49706 0 5.56641V32.4336C0 35.5029 2.49706 38 5.56641 38ZM2.22656 5.56641C2.22656 3.72485 3.72485 2.22656 5.56641 2.22656H32.4336C34.2751 2.22656 35.7734 3.72485 35.7734 5.56641V32.4336C35.7734 34.2751 34.2751 35.7734 32.4336 35.7734H24.6406V26.7187H30.0368L31.5212 17.8125H24.6406V15.5859H31.3203V6.67969H23.5273C19.2302 6.67969 15.7344 10.1755 15.7344 14.4727V17.8125H11.2812V26.7187H15.7344V35.7734H5.56641C3.72485 35.7734 2.22656 34.2751 2.22656 32.4336V5.56641Z" fill="white"/>
</svg>




             <div className="center-link">
                 <a href="https://www.facebook.com/reinhild.buchmayer" target="_blank" rel="noopener noreferrer">
                 reinhild.buchmayer
                 </a>
             </div>
         </div>
     </div>

     </div>

     </div>
        </div>
    );
}

export default withNamespaces()(Contact);
