import React from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

function Nav({ t, language, onToggle }) {
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	const toggleGerman = () => {
		changeLanguage('de');
		onToggle(true);
	};

	const toggleEnglish = () => {
		changeLanguage('en');
		onToggle(false);
	};
	return (
		<div className="scrolltrigger">
			<nav id="navBar">
				<div className="nav-content">
					<div className="logo">
						<a href="/#start">
							<h1>Reinhild Buchmayer</h1>
							<h2>Mezzosoprano</h2>
						</a>
					</div>
					<div className="burger">
						{/*
				<div className="line line-1"></div>
				<div className="line line-2"></div>
				<div className="line line-3"></div>
				*/}
						<svg width="100" height="100" viewBox="0 0 100 100">
							<path
								class="line line1"
								d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
							/>
							<path class="line line2" d="M 20,50 H 80" />
							<path
								class="line line3"
								d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
							/>
						</svg>
					</div>
				</div>

				<div className="nav-links">
					<ul>
						<li>
							<a href="/#start">{t('start')}</a>
						</li>
						<li>
							<a href="/#bio">{t('biography')}</a>
						</li>
						<li>
							<a href="/#media">{t('media')}</a>
						</li>
						<li>
							<a href="/#social">Social Media</a>
						</li>
						<li>
							<a href="/#calendar">{t('calendar')}</a>
						</li>
						<li>
							<a href="/#gallery">{t('gallery')}</a>
						</li>
						<li>
							<a href="/#contact">{t('contact')}</a>
						</li>
						<li className="language-switch">
							<a className={language ? 'active-lang' : ''} onClick={toggleGerman}>
								DE
							</a>
							<a class={language ? '' : 'active-lang'} onClick={toggleEnglish}>
								EN
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
}

export default withNamespaces()(Nav);
