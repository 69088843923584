import React, { useEffect } from 'react';
import BioContent from '../Components/bio/BioContent';

import { withNamespaces } from 'react-i18next';


function Biography({ biografie, language, t }) {
	return (
		<div>
			<div id="bio" />
			<section className="animation-bi bio-f">
				<section className="animation-bio" id="animation-bio heroBg" data-emergence="hidden">
					<div className="trigger">
						<p className="textbio" id="heroText" data-emergence="hidden">
							<p className="inside-bio">
							{biografie.slice(0, 1).map((bio, index) => {
									return <BioContent biografie={bio} key={index} language={language} />;
								})}
								{/*

							<h2 className="normal-headline">
							{t('biography')}
							</h2>
							<div className="bio-text">
							Reinhild Buchmayer studierte an der Universität Mozarteum Salzburg zuerst Klavier, bis sie neben einem Bachelorstudium in Klavier-Pädagogik 2011 auch ihren Bachelor in Gesang bei Barbara Bonney abschloss. Im Anschluss daran folgte ein Masterstudium bei Andreas Macco an der Universität Mozarteum Salzburg sowie am Leopold-Mozart-Zentrum in Augsburg bei Edda Sevenich. Meisterkurse bei Angelika Kirchschlager und Tom Krause sowie Konzerte in Deutschland, Österreich und Italien ergänzten ihr Studium. Seit 2009 ist die gebürtige Salzburgerin in größeren und kleineren Partien auf unterschiedlichsten Bühnen zu sehen. Zu ihren aktuellsten Engagements als Opernsängerin gehören u.a. 2012 die Partie des Sesto in »La clemenza di Tito« im Circus Krone in München, 2013 Erster Lehrbube in »Die Meistersinger von Nürnberg« bei den Salzburger Festspielen unter Daniele Gatti, 2014 verschiedene Konzerte in Deutschland mit dem Barockensemble der Juilliard School New York sowie 2015 die Rolle der Belisa in »Il re Teodoro in Venezia« von Giovanni Paisiello an der Nationaloper Bratislava. In der Spielzeit 2015 / 16 gab Reinhild Buchmayer ihr Debüt an der Semperoper Dresden als Nanette in Jens-Daniel Herzogs Neuinszenierung von Albert Lortzings »Der Wildschütz«. <br />
							Im Sommer 2016 war Reinhild Buchmayer als Rinaldo in Händels Rinaldo  und Mercedes in Carmen bei den Sommerfestspielen auf Gut Immling zu erleben. Im Mai 2017 kehrte die junge Mezzosopranistin an die Semperoper zurück, für die Wiederaufnahme von “Der Wildschütz”. <br />
							2017 gab Sie ihr Debut am Landestheater Niederbayern als Lisetta in “die Welt auf dem Monde”. Danach folgten weitere Rollen am Landestheater Niederbayern wie zum Beispiel: Smeton in Donizettis “Anna Bolena”, Polinesso in Händels “Ariodante” und Floßhilde in Wagners “Rheingold” Seit Oktober 2019 ist Reinhild Buchmayer Ensemble Mitglied am Landestheater Niederbayern. 

							</div>

							*/}

							</p>
						</p>

					</div>

				</section>
			</section>
		</div>
	);
}

export default withNamespaces()(Biography);
