import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import $ from 'jquery';
import SimpleReactLightbox from 'simple-react-lightbox'


ReactDOM.render(
	<React.StrictMode>
		<Router>
    <SimpleReactLightbox>
			<App />
    </SimpleReactLightbox>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);






$('nav .nav-content .burger, nav .nav-links li a').click(function() {
	$('nav').toggleClass('nav-active');
	$(this).toggleClass('nav-active-burger');
});

/* 

  var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.querySelector('.navbar-desktop h1 a').classList.add('good');
        document.querySelector('.navbar-desktop .nav-content').style.margin = "0px 7.5vw 20px 7.5vw";
        document.querySelector('.navbar-desktop .logo p').style.fontSize = "17px";
        document.querySelector('.navbar-desktop .language-switch').style.paddingBottom = "10px";
        

        document.querySelector('.nav-content h1 a').style.fontSize = "30px";
      document.querySelector('.nav-content').style.margin = "20px 7.5vw 20px 7.5vw";
      document.querySelector('.logo p').style.fontSize = "17px";
      document.querySelector('.burger').style.opacity = "1";
  
  } else {
    document.querySelector('.navbar-desktop h1 a').style.fontSize = "62px";
    document.querySelector('.navbar-desktop .nav-content').style.margin = "70px 7.5vw 20px 7.5vw";
    document.querySelector('.navbar-desktop .logo p').style.fontSize = "30px";
    document.querySelector('.navbar-desktop .language-switch').style.paddingBottom = "0px";



    document.querySelector('.nav-content h1 a').style.fontSize = "35px";
  document.querySelector('.nav-content').style.margin = "55px 7.5vw 20px 7.5vw";
  document.querySelector('.logo p').style.fontSize = "22px";
  document.querySelector('.burger').style.opacity = "0";


  }
  prevScrollpos = currentScrollPos;
};
*/

  /* SMOOTH SCROLL */ 
  $('nav .nav-links ul li a, nav .logo a').on('click', function(e) {
    if(this.hash !== ''){
      e.preventDefault();
      const hash = this.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800);
    }
  });




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
