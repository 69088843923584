import './Styles/App.scss';
import FooterStart from './Components/Common/FooterStart';
import Nav from './Components/Common/Nav';
import NavExtra from './Components/Common/NavExtra';
import Imprint from './Pages/Imprint';
import Privacy from './Pages/Privacy';
import Hero from './Pages/Hero';
import Bio from './Pages/Bio';
import Gallery from './Pages/Gallery';
import Media from './Pages/Media';
import Social from './Pages/Social';
import Calendar from './Pages/Calendar';
import Contact from './Pages/Contact';
import { Switch, Route } from 'react-router-dom';
import { client } from './client';
import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from './i18n';
import CookieConsent, { Cookies } from 'react-cookie-consent';

function App() {
	const [ events, setEvents ] = useState([]);
	const [ biografie, setBiografie ] = useState([]);
	const [ publications, setPublications ] = useState([]);
	const [ germanLanguage, toggleGermanLanguage ] = useState(true);

	useEffect(() => {
		if (localStorage.getItem('i18nextLng') === 'de') {
			toggleGermanLanguage(true);
		} else {
			toggleGermanLanguage(false);
		}

		client
			.getEntries()
			.then((response) => {
				let events = [];
				let biografie = [];
				let publications = [];
				response.items.map((item, index) => {
					let actualItem = item.sys.contentType.sys.id;
					if (actualItem === 'event') {
						events.push(item);
					} else if (actualItem === 'biografie') {
						biografie.push(item);
					} else if (actualItem === 'publication') {
						publications.push(item);
					}
					return null;
				});
				setEvents(events);
				setBiografie(biografie);
				setPublications(publications);
			})
			.catch(console.error);
	}, []);
	return (
		<div className="App">
			<Switch>
				<Route exact path="/">
					<Nav language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<CookieConsent
						location="bottom"
						buttonText="Accept"
						cookieName="cookieSao"
						style={{ background: '#000001', marginLeft: '0px' }}
						buttonStyle={{
							color: '#000000',
							fontSize: '13px',
							background: '#FFFFFF',
							padding: '7px 45px',
							margin: '20px 25px'
						}}
						expires={150}
					>
						<p style={{ marginBottom: '-10px', marginLeft: '10px', fontSize: '13px' }}>
							We and some of our partners use cookies and similar technologies for the purposes described
							in the Cookie Policy.<br /> Cookies and similar technologies.<br />
							You consent to the use of such technologies by closing this notice.
						</p>
						<br />{' '}
						<span style={{ fontSize: '13px', color: '#000000', marginLeft: '10px' }}>
							<a href="/datenschutz" style={{ fontSize: '13x', color: '#FFFFFF' }}>
								Learn more
							</a>
						</span>
					</CookieConsent>
					<Hero language={germanLanguage} />
					<Bio biografie={biografie} language={germanLanguage}/>
					<Media language={germanLanguage} />
					<Social />
					<Calendar language={germanLanguage} events={events} />
					<Gallery />
					<Contact />
					<FooterStart language={germanLanguage} />
				</Route>
				<Route exact path="/calendar">
					<NavExtra language={germanLanguage} />
					<FooterStart language={germanLanguage} />
				</Route>
				<Route exact path="/imprint">
					<NavExtra language={germanLanguage} />
					<Imprint language={germanLanguage} />
					<FooterStart language={germanLanguage} />
				</Route>
				<Route exact path="/datenschutz">
					<NavExtra language={germanLanguage} />
					<Privacy language={germanLanguage} />
					<FooterStart language={germanLanguage} />
				</Route>
			</Switch>
		</div>
	);
}

export default withNamespaces()(App);
