import React from 'react';


function Imprint() {

    return(
        <div className="imprint-content">
            <p lang="en-US" class="western">
<font color="#2d333d"><font face="Arial, serif"><font size="5"><span lang="de-DE"><b className="headline">Impressum</b></span></font></font></font></p>
<p lang="en-US" class="western" >
<font color="#2d333d"><span >&nbsp;</span></font></p>
<p lang="en-US" class="western" >
<font color="#2d333d"><font face="Arial, serif"><span lang="de-DE"><span >Angaben
gem&auml;&szlig; &sect;5 TMG:</span></span></font></font></p>
<p lang="en-US" class="western" >
<font color="#2d333d"><font face="Arial, serif"><span lang="de-DE"><span >www.reinhildbuchmayer.com
ist ein Angebot von</span></span></font></font></p>
<p lang="en-US" class="western" >
<font color="#2d333d"><font face="Arial, serif"><span lang="de-DE"><span >
Reinhild Buchmayer</span></span></font></font></p>

<br/>
<p lang="en-US" class="western" >
<font color="#2d333d"><font face="Arial, serif"><span lang="de-DE"><span >E-Mail-Adresse:
reinhild188@gmail.com</span></span></font></font></p>
<p class="western" ><br/>

</p>
<p lang="en-US" class="western" >
<font color="#2d333d"><font face="Arial, serif"><span >Webdesign
&amp; Umsetzung:</span></font></font></p>
<p lang="en-US" class="western" >
<a href="http://www.dreher-media.de/"><font color="#954f72"><font face="Arial, serif"><u><span >www.dreher-media.de<br/>
<br/>
<br/>
</span></u></font></font></a><br/>
<br/>

</p>
<p lang="en-US" class="western" >
<br/>

</p>
<p lang="en-US" class="western" align="justify" >
<font color="#333333"><font face="Arial, serif"><span lang="de-DE"><b>Haftung
f&uuml;r Inhalte</b></span></font></font></p>
<p lang="en-US" class="western" align="justify" >
<font color="#333333"><font face="Arial, serif"><span lang="de-DE">Als
Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r
eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
gespeicherte fremde Informationen zu &uuml;berwachen oder nach
Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
hinweisen.</span></font></font></p>
<p lang="en-US" class="western" align="justify" >
<font color="#333333"><font face="Arial, serif"><span lang="de-DE">Verpflichtungen
zur Entfernung oder Sperrung der Nutzung von Informationen nach den
allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine
diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der
Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei
Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
Inhalte umgehend entfernen.</span></font></font></p>
<p lang="en-US" class="western" >
<font color="#000000"><font face="Arial, serif"><span lang="de-DE"><b>Haftung
f&uuml;r Links</b></span></font></font></p>
<p lang="en-US" class="western" >
<font color="#000000"><font face="Arial, serif"><span lang="de-DE">Unser
Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren
Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r
diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r
die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
&uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der
Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
Rechtsverletzungen werden wir derartige Links umgehend entfernen.</span></font></font></p>
<p lang="en-US" class="western" >
<font color="#000000">&nbsp;</font></p>
<p lang="en-US" class="western" align="justify" >
<font color="#333333"><font face="Arial, serif"><span lang="de-DE"><b>Urheberrecht</b></span></font></font></p>
<p lang="en-US" class="western" align="justify" >
<font color="#333333"><font face="Arial, serif"><span lang="de-DE">Die
durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung,
Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb
der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen
Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
kommerziellen Gebrauch gestattet.</span></font></font></p>
<p lang="en-US" class="western" align="justify" >
<font color="#333333"><font face="Arial, serif"><span lang="de-DE">Soweit
die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
werden die Urheberrechte Dritter beachtet. Insbesondere werden
Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
werden wir derartige Inhalte umgehend entfernen.</span></font></font></p>
<p lang="en-US" class="western">
<font color="#000000">&nbsp;</font></p>
<p lang="en-US" class="western">
<font color="#2d333d"><font face="Arial, serif"><span lang="de-DE">Die
Europ&auml;ische Kommission stellt eine Plattform f&uuml;r die
au&szlig;ergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,
die unter<a href="http://www.ec.europa.eu/consumers/odr">
</a></span></font></font><a href="http://www.ec.europa.eu/consumers/odr"><font color="#002161"><font face="Arial, serif"><span lang="de-DE"><u>www.ec.europa.eu/consumers/odr</u></span></font></font></a><font color="#2d333d"><font face="Arial, serif"><span lang="de-DE">
aufrufbar ist. Wir sind nicht bereit und nicht verpflichtet, an
dieser Streitbeilegung teilzunehmen.&nbsp;</span></font></font></p>
<p lang="en-US" class="western" >
<font color="#000000">&nbsp;</font></p>
<p lang="en-US" class="western" >
<font color="#000000">&nbsp;</font></p>
<p class="western" ><br/>

</p>
<p lang="en-US" class="western">
<br/>

</p>
        </div>
    );
}

export default Imprint;