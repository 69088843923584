import React from 'react';
import { withNamespaces } from 'react-i18next';

import moment from 'moment';
import { ClickAwayListener } from '@material-ui/core';


function EventModal({ event, onClose, eventModalOpen, language, t }) {
	if (!eventModalOpen) return null;
	const { title, subtitle, begin, ticketSale, locationMap, location, locationName, ticketUrl } = event.fields;
	return (
		<div id="myModal" className="modal">
			<ClickAwayListener onClickAway={onClose}>
				<div className="modal-content">
					<span className="close0 close" onClick={onClose}>
						&times;
					</span>
					<h1>{title}</h1>
					<p className="sub">{subtitle}</p>
					<p className="information-2">
						<b>{t('date')}:</b>{' '}
						{language ? moment(begin).format('DD.MM.YYYY') : moment(begin).format('MM-DD-YYYY')}
						<br /> <b>{t('begin')}:</b>{' '}
						{language ? moment(begin).format('HH:mm') : moment(begin).format('h:mm A')}
						<br />
						<b>{t('location')}:</b>{' '}
						{location}
						<br />
						<b>{t('event location')}:</b>{' '}
						{locationName}
						<br />
						<b>{t('advance ticket sale')}:</b>{' '}
						<br />
						{ticketSale}
					</p>
					<a href={ticketUrl} target="_blank">Tickets</a>
					{locationMap ? (
						<div className="map">
							<iframe
								title="map-1"
								width="700"
								height="440"
								src={`https://www.google.com/maps/embed/v1/place?q=${locationMap.lat},${locationMap.lon}&zoom=15&key=AIzaSyAwx_D7IsPWPadLMAcTFH30Zzu831sKaHU`}
								frameBorder="0"
								scrolling="no"
								marginHeight="0"
								marginWidth="0"
							/>

							<div className="iframe-style" />
							<br />
							<div className="iframe-style" />
						</div>
					) : null}
					<br />
				</div>
			</ClickAwayListener>
		</div>
	);
}

export default withNamespaces()(EventModal);
