import React from 'react';
import moment from 'moment';
import { useState } from 'react';
import EventModal from './EventModal';
import { withNamespaces } from 'react-i18next';


const Event = ({ event, number, language, t }) => {
	const [ eventModalOpen, toggleEventModal ] = useState(false);
	const { title, subtitle, date, begin, tickets, location } = event.fields;
	const eventNumber = number + 1;
	const eventDate = moment(date).format('DD/MM/YYYY-hh:mm:ss');
	const today = moment().format('DD/MM/YYYY-hh:mm:ss');
	const dayToday = moment(date).format('DD');
	const isInThePast = moment(eventDate).diff(today) < 0;
	return (
		<div className={isInThePast ? 'item old-event' : 'item'}>
			{/* <div className="first-view">
				<h3>{language ? moment(begin).format('DD.MM.YYYY') : moment(begin).format('MM-DD-YYYY')}</h3>
				<h5>{title}</h5>
				<p>
					<b>location: </b>
					{location}
					<br />
					<b>begin:</b> {language ? moment(begin).format('HH:mm') : moment(begin).format('h:mm A')}
				</p>
				<button id="calendar-1" className="view-more" onClick={() => toggleEventModal(true)}>
					more information
				</button>
			</div> */}

			<div className="calendar-inner-grid">
				<div className="item">
					<p>{dayToday}</p>
				</div>
				<div className="item">
					<h2>{title}</h2>
					<p>
						{location} | {language ? moment(begin).format('HH:mm') : moment(begin).format('h:mm A')}
					</p>
					<a className="view-more-btn" onClick={() => toggleEventModal(true)}>
					{t('more information')}
					</a>
				</div>
			</div>
			<EventModal
				event={event}
				eventModalOpen={eventModalOpen}
				language={language}
				onClose={() => toggleEventModal(false)}
			/>
		</div>
	);
};

export default withNamespaces()(Event);
