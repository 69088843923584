import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

function Nav({ language, t }) {
	return (
		<div className="scrolltrigger" >
		<nav id="navBar" className="nav-normal">
			<div className="nav-content">
			<div className="logo">
				<a href="/">
					<h1>
						Reinhild Buchmayer
					</h1>
					<h2>
						Mezzosoprano
					</h2>
				</a>

			</div>
			<div className="burger"> 
				{/*
				<div className="line line-1"></div>
				<div className="line line-2"></div>
				<div className="line line-3"></div>
				*/}
				  <svg width="100" height="100" viewBox="0 0 100 100">
        <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
        <path class="line line2" d="M 20,50 H 80" />
        <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
      </svg>
			</div>
			</div>

			<div className="nav-links">
				<ul>
				<li>
					<a href="/">Start</a>
				</li>
				<li>
					<a href="/">Biografie</a>
				</li>
				<li>
					<a href="/">Media</a>
				</li>
				<li>
					<a href="/">Social Media</a>
				</li>
				<li>
					<a href="/">Kalender</a>
				</li>
				<li>
					<a href="/">Galerie</a>
				</li>
				<li>
					<a href="/">Kontakt</a>
				</li>
				<li className="language-switch">
					<a href="" className="active-lang">DE</a>
					<a href="">EN</a>
				</li>
				</ul>
			</div>
		</nav>
		</div>
	);
}

export default withNamespaces()(Nav);
