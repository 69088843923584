import React, { useState } from 'react';
import EventList from '../Components/calendar/EventList';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import Event from '../Components/calendar/Event';
import { withNamespaces } from 'react-i18next';


function Calendar({ events, t, language }) {
	const sortedEvents = events.sort((a, b) => moment(a.fields.begin).diff(b.fields.begin));
	const januaryEvents = [];
	const februaryEvents = [];
	const marchEvents = [];
	const aprilEvents = [];
	const mayEvents = [];
	const juneEvents = []; 
	const julyEvents = [];
	const augustEvents = [];
	const septemberEvents = [];
	const octoberEvents = [];
	const novemberEvents = [];
	const decemberEvents = [];

	const sortEvents = () => {
		sortedEvents.forEach((event) => {
			if (new Date(event.fields.begin).getMonth() === 0) {
				januaryEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 1) {
				februaryEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 2) {
				marchEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 3) {
				aprilEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 4) {
				mayEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 5) {
				juneEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 6) {
				julyEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 7) {
				augustEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 8) {
				septemberEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 9) {
				octoberEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 10) {
				novemberEvents.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 11) {
				decemberEvents.push(event);
			}
		});
	};

	sortEvents();

	return (
		<div>
			<div id="calendar" />
			<div className="calendar-section">
				<div className="item-calendar">
					<h2 className="normal-headline">        {t('calendar')}</h2>
					<div className="calendar-grid">
						{/* 
						<EventList events={events}/>
						*/}
						

							

						{januaryEvents.length > 0 ? (
							<Collapsible className="month" class trigger="Januar 2022">
								{januaryEvents.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{februaryEvents.length > 0 ? (
							<Collapsible className="month" class trigger="Februar 2021">
								{februaryEvents.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{marchEvents.length > 0 ? (
							<Collapsible className="month" class trigger="März 2021">
								{marchEvents.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{aprilEvents.length > 0 ? (
							<Collapsible className="month" class trigger="April 2021">
								{aprilEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{mayEvents.length > 0 ? (
							<Collapsible className="month" class trigger="Mai 2021">
								{mayEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{juneEvents.length > 0 ? (
							<Collapsible className="month" class trigger="Juni 2021">
								{juneEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{julyEvents.length > 0 ? (
							<Collapsible className="month" class trigger="Juli 2021">
								{julyEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{augustEvents.length > 0 ? (
							<Collapsible className="month" class trigger="August 2021">
								{augustEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{septemberEvents.length > 0 ? (
							<Collapsible className="month" class trigger="September 2021">
								{septemberEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{octoberEvents.length > 0 ? (
							<Collapsible className="month" class trigger="Oktober 2021">
								{octoberEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{novemberEvents.length > 0 ? (
							<Collapsible className="month" class trigger="November 2021">
								{novemberEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{decemberEvents.length > 0 ? (
							<Collapsible className="month" class trigger="Dezember 2021">
								{decemberEvents.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
		{events.length === 0 ? (
				<div className="no-event-grid">
				<div className="no-events">{t('no upcoming events')}</div>
			</div>
			) : (
				<div>
					</div>
			)}

					</div>
				</div>
			</div>
		</div>
	);
}

export default withNamespaces()(Calendar);
