import React from 'react';
import { withNamespaces } from 'react-i18next';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";


function Gallery({ language, t }) {
    const option = {
            settings: {
              autoplaySpeed: 3000,
              boxShadow: 'none',
              disableKeyboardControls: false,
              disablePanzoom: true,
              disableWheelControls: false,
              hideControlsAfter: 3000,
              lightboxTransitionSpeed: 0.3,
              lightboxTransitionTimingFunction: 'linear',
              overlayColor: 'rgba(35, 35, 35, 0.9)',
              slideAnimationType: 'fade',
              slideSpringValues: [300, 50],
              slideTransitionSpeed: 0.6,
              slideTransitionTimingFunction: 'linear',
              usingPreact: false
            }
          };
    return(
        <div>
			<div id="gallery"></div>
		<div className="gallery-section">
			<div className="container-headline">
		<h2 className="normal-headline fade" data-emergence="hidden">
        {t('gallery')}
            </h2>
			</div>
			<div className="desktop-gallery">
            <SimpleReactLightbox>
			<SRLWrapper options={option}>

                <div className="gallery-grid">
                    {/* ROW 1 */}
                    <div className="sub-grid sub-grid-1">

                        <div className="item-overlay">
                            <a href="img/gallery-1.jpg">
                                <img src="img/gallery-1-s.jpg" alt=""/>
                            </a>
                        </div>
                                <div className="item-overlay">
                                 <a href="img/gallery-2.jpg">

                                    <img className="gallery100" src="img/gallery-2-s.jpg" alt="" />
                                    </a>
                                </div>
                                <div className="item-overlay">
                                 <a href="img/gallery-3.jpg">

                                    <img src="img/gallery-3-s.jpg" alt="" />
                                    </a>
                                </div>

                            
                      

                    </div>
 {/* ROW 2 */}
 <div className="sub-grid sub-grid-2">


    <div className="item-overlay">

    <a href="img/gallery-4.jpg">

        <img src="img/gallery-4-s.jpg" alt="" />
        </a>
    </div>
            <div className="item-overlay">
    <a href="img/gallery-5.jpg">

                <img src="img/gallery-5-s.jpg" alt=" " />
                </a>
            </div>

        
  

</div>

 {/* ROW 3 */}
 <div className="sub-grid sub-grid-3">
    <div className="item">
            <div className="item-overlay first">
                <a href="img/gallery-6.jpg">
                    <img src="img/gallery-6-s.jpg" alt=" " />
                </a>
            </div>
                <div className="item-overlay second">
                    <a href="img/gallery-7.jpg">
                        <img src="img/gallery-7-s.jpg" alt=" " />
                    </a>
                </div>
        </div>

            <div className="item-overlay">
                <a href="img/gallery-8.jpg">
                <img src="img/gallery-8-s.jpg" alt=" " />
                </a>
            </div>
            <div className="item-overlay">
            <a href="img/gallery-9.jpg">

                <img src="img/gallery-9-s.jpg" alt=" " />
                </a>
            </div>
        
  

</div>

 					

                </div>
                </ SRLWrapper>
            </ SimpleReactLightbox>

				</div>
				<div className="mobile-gallery">
            <SimpleReactLightbox>

			<SRLWrapper options={option}>

                <div className="gallery-grid">
                             {/* ROW 1 */}
                             <div className="sub-grid sub-grid-1">
                             <div className="item-overlay">

<a href="img/gallery-1.jpg">
        <img src="img/gallery-1-s.jpg" loading="lazy"  />
</a>
</div>
<div className="item-overlay">

        <a href="img/gallery-2.jpg">
                <img src="img/gallery-2-s.jpg" loading="lazy"  />
        </ a>
        </div>

        
  

</div>
{/* ROW 2 */}
<div className="sub-grid sub-grid-1">
<div className="item-overlay">

<a href="img/gallery-3.jpg">
<img src="img/gallery-3-s.jpg" loading="lazy"  />
</a>
</div>
<div className="item-overlay">

<a href="img/gallery-4.jpg">
<img src="img/gallery-4-s.jpg" loading="lazy"  />
</ a>
</div>




</div>

{/* ROW 3 */}
<div className="sub-grid sub-grid-1">
<div className="item-overlay">

<a href="img/gallery-5.jpg">
<img src="img/gallery-5-s.jpg" loading="lazy"  />
</a>
</div>
<div className="item-overlay">

<a href="img/gallery-6.jpg">
<img src="img/gallery-6-s.jpg" loading="lazy"  />
</ a>
</div>





</div>
                </div>
                </ SRLWrapper>
            </ SimpleReactLightbox>
                
				</div>
		</div>


		
		</div>
		
     

	);
}

export default withNamespaces()(Gallery);
