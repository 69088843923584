import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

function FooterStart({ language, t }) {

    return(
        
        <div id="start" className="hero-section">
            <div id="heroBg" className="hero-image"></div>
            <div className="container-name">
                <div className="container" id="heroText">
                    <h2>Reinhild Buchmayer</h2>
                    <p>Mezzosoprano | Operasinger</p>
             </div>
            </div>
        </div>

	);
}

export default withNamespaces()(FooterStart);
